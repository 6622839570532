// Pico lightweight version
@use "../../node_modules/@picocss/pico/scss/index" with (
    $enable-semantic-container: true,
    $enable-classes: true,
    $modules: ("content/code": false,
        "forms/input-color": false,
        "forms/input-date": false,
        "forms/input-file": false,
        "forms/input-range": false,
        "forms/input-search": false,
        "components/accordion": false,
        "components/card": false,
        "components/dropdown": false,
        "components/loading": false,
        "components/modal": false,
        "components/nav": false,
        "components/progress": false,
        "components/tooltip": true,
        "utilities/accessibility": false,
        "utilities/reduce-motion": false));

//PICO EXAMPLE
//IF WE EVER WANT TO REFERENCE THE BUILT IN PICO COLOR LIBRARY
//https://picocss.com/docs/colors#close
// @use "../../node_modules/@picocss/pico/scss/colors/index" as *;
// h2 {
//     color: $pink-500;
//   }

//PICO EXAMPLE
//OVERRIDE ALL PICO CSS VARIABLES WITHIN ITS LIGHT VERSION
//https://picocss.com/docs/css-variables
/* Light color scheme (Default) */
/* Can be forced with data-theme="light" */

[data-theme="light"],
:root:not([data-theme="dark"]) {

    --nick-sand-white:#fff;
    --nick-sand-darkest: #212529;
    --nick-sand-dark: #5d4d28;
    --nick-sand-light: #f6cf73;
    --nick-sand-mid: #d4b263;
    --nick-sand-burnt: #cb405f;
    --nick-green: DarkSlateGrey;

    --nick-img-album-covers-local: url('../images/sprite_album_covers_2023.png');
    //we can do this if the file is stored in Firebase Storage!!
    // --nick-img-album-covers-fbs: url('https://lh3.googleusercontent.com/d/1DreOTPlCa_u_vfy9Zq3V-GD7mkWj5lLQ=w1000');

    --nick-spacing-sm: 1rem;
    --nick-spacing-mid: 1.25rem;
    --nick-spacing-lg: 2rem;
 
    --pico-spacing: 1rem;
    --pico-line-height: 1.5;
    --pico-typography-spacing-top: 1.5rem;
    --pico-typography-spacing-vertical: 0.5rem;
     
    --pico-font-size: 100%;
    --pico-font-family: 'Nunito Sans', sans-serif;
    --pico-font-weight: 400;

    --pico-color: var(--nick-sand-dark);
    --pico-background-color: var(--nick-sand-light);
    --pico-text-selection-color:var(--nick-sand-mid);

    --pico-blockquote-border-color: var(--nick-sand-mid);
    --pico-blockquote-footer-color: var(--nick-sand-dark);

    --pico-button-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --pico-button-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    --pico-primary-background: var(--nick-sand-burnt); //this determins the colors of my buttons

    --pico-form-element-background-color: #fbfcfc;
    --pico-form-element-selected-background-color: #dfe3eb;
    --pico-form-element-border-color: #cfd5e2;
    --pico-form-element-color: #23262c;
    --pico-form-element-placeholder-color: var(--pico-muted-color);
    --pico-form-element-active-background-color: #fff;
    --pico-form-element-active-border-color: var(--pico-primary-border);
    --pico-form-element-focus-color: var(--pico-primary-border);
    --pico-form-element-disabled-opacity: 0.5;
    --pico-form-element-invalid-border-color: #b86a6b;
    --pico-form-element-invalid-active-border-color: #c84f48;
    --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
    --pico-form-element-valid-border-color: #4c9b8a;
    --pico-form-element-valid-active-border-color: #279977;
    --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
}

[data-theme=light] input:is([type=submit],
    [type=button],
    [type=reset],
    [type=checkbox],
    [type=radio],
    [type=file]),
:root:not([data-theme=dark]) input:is([type=submit],
    [type=button],
    [type=reset],
    [type=checkbox],
    [type=radio],
    [type=file]) {
    --pico-form-element-focus-color: var(--pico-primary-focus);
}