//STYLES | MODIFIED ON 2024-05-10

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  body {
    //SCROLL BTN TO HELP NAVIGATE BACK TO TOP
    scroll-margin-top: 50px;
    .scroll-click {
      position: fixed;
      bottom: 10rem;
      right: 3rem;
      z-index: 2;
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      border: 0;
      outline: 0;
      background-color: var(--nick-sand-dark);
      color: var(--nick-sand-light);
      border-radius: 0.25rem;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0.7;

      &:hover {
        cursor: pointer;
        color: var(--nick-sand-light);
        background-color: var(--nick-sand-dark);
      }

      &:active {
        background-color: var(--nick-sand-darkest);
      }

      &.visible {
        visibility: visible;
      }
    }

    .shadow-box {
      @include shadow();
    }

    header,
    main,
    footer {
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
    }

    header {
      margin: 1rem auto 2rem;
      padding: 0;
      background: var(--nick-sand-burnt) url(#{$bg-path}/bg_top_1000x150.jpg)
        no-repeat 0 0;
      width: 90%;

      .site-title {
        padding: 1.5rem;
        line-height: 1;
        font-size: 2.25rem;
        font-weight: 600;
        color: var(--nick-sand-white);
      }

      .site-menu {
        padding: 0 1.5rem;

        li {
          list-style: none;
          display: inline-block;
          padding: 0 0.5rem 0 0;
          color: var(--nick-sand-white);
          font-family: var(--pico-font-family);

          a {
            color: var(--nick-sand-white);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            &:active {
              color: var(--nick-sand-light);
            }
          }
        }
      }
    }

    main {
      .specials {
        details {
          .special-tracks {
            li {
              margin: 1rem 0;

              p,
              span {
                color: var(--nick-sand-dark);
                word-break: break-all;
                display: block;
              }

              .copy {
                a {
                  @media (min-width: 508px) {
                    max-width: 50px;
                    margin: 0 1rem;
                  }

                  &[data-tooltip]::before {
                    background-color: var(--nick-sand-darkest);
                    color: var(--nick-sand-white);
                  }

                  &[data-tooltip]::after {
                    color: var(--nick-sand-darkest);
                  }
                }

                @media (min-width: 508px) {
                  flex: 0 0 auto;
                  min-width: auto;
                }
              }
            }
          }
        }
      }

      details {
        > summary {
          list-style: none;

          &::marker {
            display: none;
          }
        }

        summary {
          &:focus {
            outline: solid 1px var(--nick-sand-light);
          }
        }

        h2 {
          padding: var(--nick-spacing-mid);

          background-color: var(--nick-sand-mid);
          border-color: var(--nick-sand-mid);
          border-radius: var(--pico-border-radius);

          color: var(--nick-sand-dark);
          font-size: 1.5rem;
          font-weight: var(--pico-font-weight);
          text-transform: uppercase;
          -webkit-text-decoration-color: var(--nick-sand-mid);
          text-decoration-color: var(--nick-sand-mid);
          transition: 0.3s;

          &:hover {
            cursor: pointer;
            color: var(--nick-sand-white);
            background-color: var(--nick-sand-dark);
          }

          &:active {
            background-color: var(--nick-sand-dark);
            border-color: var(--nick-sand-dark);
          }

          &:focus {
            outline-color: var(--nick-sand-mid);
          }
        }

        h3 {
          width: 100%;
          text-align: center;
          padding: var(--nick-spacing-sm);
          font-size: 2.5rem;
          font-weight: var(--pico-font-weight);
          color: var(--nick-sand-dark);
        }

        .txt-preview {
          max-width: 15.625rem;
          text-align: center;
          margin: var(--nick-spacing-sm);
          font-size: 1rem;
          color: var(--nick-sand-light);
          background-color: var(--nick-sand-dark);
          border: solid 0.225rem var(--nick-sand-dark);
          border-radius: var(--pico-border-radius);
        }

        .border-top {
          border-top: solid 0.2rem var(--nick-sand-mid);
        }

        blockquote {
          margin: var(--nick-spacing-sm);
          border-inline-start-width: 0.6rem;
        }

        .special-tracks,
        .album-tracks {
          margin: 1rem 3rem;
          list-style: none;

          li {
            a {
              width: 100%;
              // color: var(--nick-sand-dark);
              // text-underline-offset: 0.25rem;
              // text-decoration-thickness: 0.1rem;
              // text-decoration-color: var(--nick-sand-dark);

              // &:hover {
              //     color: var(--nick-sand-darkest);
              // }
            }
          }
        }

        .album-cover {
          max-width: 18.25rem;
          width: 100%;
          height: 16.375rem;
          text-indent: -624.938em;
          margin-top: 1rem;
          background-repeat: no-repeat;
          border: solid 0.25rem var(--nick-sand-dark);

          &.bg-local {
            background: var(--nick-img-album-covers-local);
          }

          &.bg-live {
            background: var(--nick-img-album-covers-live);
          }

          &.where-do-we-go-from-here {
            background-position: left 0;
          }

          &.good-soil {
            background-position: left -16.56rem;
          }

          &.the-best-for-now {
            background-position: left -32rem;
          }
        }
      }

      .about {
        .about-cover {
          img {
            border: solid 0.5rem var(--nick-sand-dark);
            @include shadow(
              1px,
              17px,
              34px,
              -16px,
              rgba(0, 0, 0, 0.75),
              inset 0 0 0
            );
          }
        }

        p {
          padding: var(--nick-spacing-sm);
        }
      }

      .track {
        position: relative;

        i {
          position: absolute;
          top: 15px;
          left: 20px;
          display: block;

          &.fa-play {
            transition: opacity 0.3s, transform 0.3s;
          }

          &.fa-spinner {
            transition: opacity 0.3s, transform 0.3s;
            transform: rotate(-180deg);
            opacity: 0;
          }
        }

        &:hover {
          background-color: var(--nick-sand-dark);

          .fa-spinner {
            transform: rotate(0deg);
            opacity: 1;
          }

          .fa-play {
            transform: rotate(180deg) scale(0.5);
            opacity: 0;
          }
        }

        &.js-icon-rotate {
          background-color: var(--nick-sand-dark);

          .fa-play {
            opacity: 0;
          }

          .fa-spinner {
            opacity: 1;
            animation: spinner 1.5s linear infinite;
          }

          @keyframes spinner {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }

      .non-btn {
        background-color: var(--pico-secondary-hover-background);
        border-color: var(--pico-secondary-hover-border);
        color: var(--pico-secondary-inverse);
        padding: var(--pico-form-element-spacing-vertical)
          var(--pico-form-element-spacing-horizontal);
        border: var(--pico-border-width) solid var(--pico-border-color);
        border-radius: var(--pico-border-radius);
        outline: none;
        font-weight: var(--pico-font-weight);
        font-size: 1rem;
        text-align: center;
        text-decoration: none;
      }

      .gigs {
        a {
          padding: var(--nick-spacing-sm);
        }

        .gcal {
          width: 100%;
          margin: 2rem auto;
          border: 0;
        }
      }

      .contact {
        form {
          padding: var(--nick-spacing-sm);
        }
      }

      //A SHORT CSS3 ANIMATION FOR DETAILS & SUMMARY

      details[open] summary ~ * {
        animation: sweep 0.5s ease-in-out;
      }

      @keyframes sweep {
        0% {
          opacity: 0;
          margin-top: -10px;
        }

        100% {
          opacity: 1;
          margin-top: 0;
        }
      }
    }

    footer {
      .btm {
        .col {
          min-width: initial !important;
          align-items: center;
        }

        img {
          margin-bottom: 2.5rem;
        }

        a {
          display: block;
          width: 12.5rem;
          height: 3.125rem;
          margin: 0.4em;
          text-indent: -9999px;
          opacity: 0.7;
          background-image: url(#{$bg-path}/sprite_social_icons_2023.png);
          background-color: transparent;
          background-repeat: no-repeat;

          &.spotify {
            background-position: left -13px;
          }

          &.itunes {
            background-position: left -69px;
          }

          &.amazon {
            background-position: left -122px;
          }

          &.youtube {
            background-position: left -174px;
          }

          &.facebook {
            background-position: left -228px;
          }

          &.ukrain {
            background-position: left -287px;
          }
          &.bandcamp {
            background-position: left -325px;
          }
        }
      }
    }
  }
}
