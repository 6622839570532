//FLEXBOX GRID SYSTEM
//A CUSTOM LIGHT FLEXBOX GRID SYSTEM
//https://stackoverflow.com/questions/37386244/what-does-flex-1-mean
// align-items: effects horizontal alignment because the parent's flex-direction is set to column.  if flex direction was row, than this value would impact the opposite!!!
//https://stackoverflow.com/questions/35049262/difference-between-justify-content-vs-align-items

main,
header,
footer {

    .flex-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &.flex-full {
            flex-basis: 100%;
        }
        //the grid system
        .row {
            display: flex;
            flex-flow: row wrap;
            flex: 1 1 100%; //Three values for flex: flex-grow | flex-shrink | flex-basis

            //options for row alignment
            &.jc-start {
                justify-content: flex-start;
            }

            &.jc-end {
                justify-content: flex-end;
            }

            &.jc-center {
                justify-content: center;
            }

            &.jc-even {
                justify-content: space-evenly;
            }

            &.jc-between {
                justify-content: space-between;
            }

            &.jc-around {
                justify-content: space-around;
            }

            &.col-top-full {

                .col:not(:first-child) {
                    flex:1;
                }
                .col:nth-child(1) {
                    flex: 1 1 100%;
                }
            }

            .col {
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                min-width:300px;

                &.flex-col-rev {
                    flex-direction: column-reverse;
                }

                &.flex-wrap {
                    flex-wrap: wrap;
                }

                &.ai-content-start {
                    align-content: flex-start;
                }

                &.ai-stretch {
                    align-items: stretch; //the default
                }

                &.ai-start {
                    align-items: flex-start;
                }

                &.ai-end {
                    align-items: flex-end;
                }

                &.ai-center {
                    align-items: center;
                }

                &.ai-baseline {
                    align-items: baseline;
                }

                &.jc-center {
                    justify-content: center;
                }

                &.jc-space-between {
                    justify-content: space-between;
                }
            }
        }
        //child elements that need special attention
        .ai-self-center {
            align-self: center;
        }
        //children can override parent "justify-content" properties by using margins
        .jc-start-child {
            margin-right: auto !important;
        }

        .jc-end-child {
            margin-left: auto !important;
        }
    }
}

//IE11 Hack to make flex-basis play nice in IE11
_:-ms-fullscreen,
:root {
    main,
    header,
    footer {
        .row {
            &.IE-FlexAuto {
                flex-basis: auto;
            }
        }
    }
}
